var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var j7,k7,l7,ota,pta,qta,o7,p7,r7,s7,rta,sta,tta,u7,v7,w7,uta,vta;j7=function(a){var b=new $CLJS.h(null,3,[$CLJS.wD,$CLJS.p.h($CLJS.GD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.oD,$CLJS.U)(a)],null)};k7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.C4)};l7=function(a,b){return $CLJS.E.g($CLJS.jD.h(a),$CLJS.Dl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AD,$CLJS.iD,$CLJS.zD],null)))};
ota=function(a){return $CLJS.Q0(a,new $CLJS.h(null,1,[$CLJS.AD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.rG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.fD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.ck.g($CLJS.MY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(m7))return null;var d=m7;m7=!0;try{var e=$CLJS.I1(a,b),f=$CLJS.n(e)?$CLJS.HV(a,e):$CLJS.HV(a,b),k=function(){var m=$CLJS.n7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qU,$CLJS.EV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.QU.h(f);if($CLJS.n(u))return u;u=$CLJS.hO.h(f);if($CLJS.n(u))return u;u=$CLJS.bP.h(f);return $CLJS.n(u)?u:$CLJS.QD.h(f)}())?$CLJS.Y_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.fD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.i3($CLJS.Tj.l(l,$CLJS.Fi,$CLJS.H([$CLJS.$J,$CLJS.QV,$CLJS.LV])),null),$CLJS.U,function(){var m=$CLJS.MY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.LJ,$CLJS.yY):l:null}finally{m7=d}};
o7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.wO);var f=$CLJS.I(c,2,null);c=$CLJS.sk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ni,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ni,k],null):null}(),function(){var k=$CLJS.wQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.QV,k],null):null}(),function(){var k=$CLJS.oF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.LV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.O1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.i3(c,d):c};
p7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.oG,b):b)?$CLJS.Wi:$CLJS.zz($CLJS.ni,$CLJS.tA)(a)};
$CLJS.q7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.wD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.wQ),t=$CLJS.J.g(f,$CLJS.ni);e=$CLJS.J.g(f,$CLJS.wO);var u=$CLJS.J.g(f,$CLJS.HM),v=$CLJS.J.g(f,$CLJS.oF),x=$CLJS.sk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.JJ,$CLJS.D0,k],null);f=$CLJS.kD.h(f);a=$CLJS.n(f)?f:$CLJS.l_.j(a,b,d);c=x.call($CLJS.sk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.kD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ni,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.LV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.QV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.ZJ,u):m;return $CLJS.n(e)?$CLJS.i3(u,e):u};r7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.di.h(d),c)},a))?$CLJS.ck.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Tj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.di.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
s7=function(a){var b=$CLJS.n($CLJS.p1.h(a))?null:function(){var e=$CLJS.LJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.PY,null,$CLJS.WY,null,$CLJS.yY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.sk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.wD,$CLJS.p.h($CLJS.GD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,p7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.ZJ.h(a)))?(f=$CLJS.Zj.g($CLJS.IY,$CLJS.LJ.h(a)))?$CLJS.$Y.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.wO,e],null):null}(),function(){var e=$CLJS.i1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.wO,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.oF,e],null):null}(),function(){var e=$CLJS.PV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.PV,e],null):null}(),function(){var e=$CLJS.QV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.wQ,e],null):null}(),function(){var e=$CLJS.ZJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.HM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.MY,$CLJS.U):$CLJS.zz($CLJS.Fi,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.LJ.h(c),$CLJS.GY)},$CLJS.Y_.v(a,b,$CLJS.HV(a,b),new $CLJS.h(null,3,[$CLJS.F_,!1,$CLJS.L_,!0,$CLJS.B_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.t7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);u7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.n7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);w7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.R0.m(null,$CLJS.iF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.Q0(c,new $CLJS.h(null,2,[$CLJS.oF,$CLJS.mh,$CLJS.wQ,ota],null)),a],null)});
var m7=!1,x7=function x7(a,b){var d=$CLJS.$0(a,v7.h(b));a=$CLJS.n(v7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.kD);return $CLJS.dk.j($CLJS.R.j($CLJS.dk.j($CLJS.R.j(b,sta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),w7,$CLJS.kD.h(b)),$CLJS.kD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.Q_.m(null,$CLJS.JJ,function(a,b,c){return p7(c)});
$CLJS.Q_.m(null,$CLJS.iF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.oF);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.LV,d):c;return $CLJS.S_.j(a,b,c)});$CLJS.T_.m(null,$CLJS.JJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.T_.m(null,$CLJS.iF,function(a,b,c){var d=o7(a,b,c);b=$CLJS.q7(a,b,d,c);return $CLJS.n(v7.h(b))?x7(a,b):b});
$CLJS.M_.m(null,$CLJS.JJ,function(a,b,c,d){function e(T){var da=$CLJS.i5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.QV),m=$CLJS.J.g(k,$CLJS.ZJ),t=$CLJS.J.g(k,$CLJS.$J),u=$CLJS.J.g(k,$CLJS.di),v=$CLJS.J.g(k,$CLJS.$Y),x=$CLJS.J.g(k,w7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.I5),G=$CLJS.J.g(k,
$CLJS.kD),K=$CLJS.J.g(k,v7),S=$CLJS.g1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.kD);T=$CLJS.J.g(T,v7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.$0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.kD);oa=$CLJS.J.g(oa,v7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.m_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.$0(a,m);$CLJS.n(T)?T=$CLJS.e3($CLJS.kD.h($CLJS.o_.j(a,b,T))):(T=$CLJS.h1(a,t),T=$CLJS.l_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.i1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.Zj.g(d,$CLJS.m_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.Zj.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.Zj.g(c,e(S)):l)?e(c):c});
$CLJS.M_.m(null,$CLJS.iF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.wQ);var k=$CLJS.J.g(f,$CLJS.wO),l=$CLJS.J.g(f,$CLJS.oF);f=$CLJS.J.g(f,$CLJS.HM);$CLJS.I(c,2,null);c=o7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.$Y,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.di,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.QV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.ZJ,f):e;return $CLJS.n(e)?$CLJS.l_.v(a,b,e,d):$CLJS.HD("[Unknown Field]")});
$CLJS.N_.m(null,$CLJS.JJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.N_.m(null,$CLJS.iF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=o7(a,b,c);return $CLJS.n(c)?$CLJS.O_.j(a,b,c):"unknown_field"});
$CLJS.U_.m(null,$CLJS.JJ,function(a,b,c){return $CLJS.sk.l($CLJS.H([function(){var d=$CLJS.kH($CLJS.U_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.l_.j(a,b,c),$CLJS.n_,$CLJS.l_.v(a,b,c,$CLJS.m_)],null),$CLJS.E.g($CLJS.LJ.h(c),$CLJS.PY)?function(){var d=$CLJS.ZY.h(c);return $CLJS.n(d)?(d=$CLJS.f_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Fj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.kD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.u0.m(null,$CLJS.iF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.oF.h(b)});$CLJS.u0.m(null,$CLJS.JJ,function(a){return $CLJS.LV.h(a)});
$CLJS.t0.m(null,$CLJS.iF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.oG,b),e=$CLJS.qB($CLJS.PV,$CLJS.ni,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.oF,b,$CLJS.H([$CLJS.ni,d?$CLJS.Wi:e,$CLJS.PV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,c,a],null)}b=$CLJS.PV.h(c);c=$CLJS.n(b)?$CLJS.Tj.g($CLJS.R.j(c,$CLJS.ni,b),$CLJS.PV):c;c=$CLJS.Tj.g(c,$CLJS.oF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,c,a],null)});
$CLJS.t0.m(null,$CLJS.JJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.LV,b,$CLJS.H([$CLJS.PV,$CLJS.qB($CLJS.PV,$CLJS.ni,$CLJS.tA)(a)])):$CLJS.Tj.l(a,$CLJS.LV,$CLJS.H([$CLJS.PV]))});$CLJS.w0.m(null,$CLJS.iF,function(a,b,c){return $CLJS.x0.j(a,b,o7(a,b,c))});
$CLJS.w0.m(null,$CLJS.JJ,function(a,b,c){if($CLJS.Zj.g($CLJS.LJ.h(c),$CLJS.GY)){a=$CLJS.zz($CLJS.ni,$CLJS.tA)(c);b=null==c?null:$CLJS.VM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Fk.h($CLJS.Pi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,uta),l=$CLJS.J.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.r0.h(k),$CLJS.r0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Cq.g?$CLJS.Cq.g(1,m):$CLJS.Cq.call(null,1,m))?$CLJS.Ck:$CLJS.n($CLJS.Cq.g?$CLJS.Cq.g(31,m):$CLJS.Cq.call(null,31,m))?$CLJS.Lk:$CLJS.n($CLJS.Cq.g?
$CLJS.Cq.g(365,m):$CLJS.Cq.call(null,365,m))?$CLJS.Ri:$CLJS.Xi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Fk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.yk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Mk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?r7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.LV.h(c))?r7(d,$CLJS.t_,$CLJS.LV.h(c)):d}return $CLJS.xf});
$CLJS.f5.m(null,$CLJS.iF,function(a){var b=null==a?null:$CLJS.DD(a);b=null==b?null:$CLJS.wQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.k5,$CLJS.H([$CLJS.j5,function(c,d){return o7(c,d,a)}]))});$CLJS.f5.m(null,$CLJS.JJ,function(a){var b=null==a?null:$CLJS.QV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.k5,$CLJS.H([$CLJS.j5,$CLJS.Pe(a)]))});$CLJS.l5.m(null,$CLJS.iF,function(a,b){return $CLJS.ED(a,$CLJS.CD,$CLJS.H([$CLJS.wQ,b]))});
$CLJS.l5.m(null,$CLJS.JJ,function(a,b){return $CLJS.CD(a,$CLJS.QV,b)});$CLJS.m5.m(null,$CLJS.iF,function(a,b,c){return $CLJS.n5.j(a,b,o7(a,b,c))});
$CLJS.m5.m(null,$CLJS.JJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ni);var d=$CLJS.J.g(b,$CLJS.VM),e=$CLJS.J.g(b,$CLJS.pj);if($CLJS.Zj.g($CLJS.LJ.h(b),$CLJS.GY)){var f=function(){var m=null==a?null:$CLJS.Z0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.wQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.lj],null)),l=$CLJS.g5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=l7(S,l)?$CLJS.R.j(T,$CLJS.t_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return l7(G,l)?$CLJS.R.j(K,$CLJS.t_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.qi.h(k);return $CLJS.n(t)?$CLJS.yj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.Yi)?$CLJS.ck.g(k7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.h5(),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("Bin every 0.1 degrees"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.AD,$CLJS.zD,$CLJS.zD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("Bin every 1 degree"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.AD,$CLJS.zD,$CLJS.zD,1],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("Bin every 10 degrees"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.AD,$CLJS.zD,$CLJS.zD,10],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("Bin every 20 degrees"),$CLJS.jD,new $CLJS.h(null,
2,[$CLJS.AD,$CLJS.zD,$CLJS.zD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.lj)&&!$CLJS.Dz(e,$CLJS.$h)?$CLJS.ck.g(k7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.h5(),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("10 bins"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.AD,$CLJS.iD,$CLJS.iD,10],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("50 bins"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.AD,$CLJS.iD,$CLJS.iD,50],null)],null),new $CLJS.h(null,2,[$CLJS.kD,$CLJS.HD("100 bins"),$CLJS.jD,new $CLJS.h(null,2,[$CLJS.AD,$CLJS.iD,$CLJS.iD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.qV.m(null,$CLJS.iF,function(a){return a});
$CLJS.qV.m(null,$CLJS.JJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.LJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.wD,$CLJS.p.h($CLJS.GD()),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a),$CLJS.BK,$CLJS.U.h(a)],null),a=$CLJS.D0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,b,a],null);case "source/expressions":return j7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.oD.h(a))?j7(a):s7(a);default:return s7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.ck.g($CLJS.sV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.vl(function(t){return $CLJS.U1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.bk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.sV),m):null;return $CLJS.SV.l(e,f,$CLJS.CD,$CLJS.H([$CLJS.QD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.QD.h($CLJS.HV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.Y_.v(d,e,$CLJS.HV(d,e),new $CLJS.h(null,3,[$CLJS.F_,!1,$CLJS.L_,!1,$CLJS.B_,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.od(k)?$CLJS.ck.g(function(l){return $CLJS.R.j(l,$CLJS.NY,!0)},f):$CLJS.g3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(u7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.aj,$CLJS.BY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.t7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,u7],null)],null)],null));